/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
 */
import React from 'react';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonItem,
    IonLabel,
    IonContent,
    IonPage,
    withIonLifeCycle,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonBackButton,
    IonFooter,
    IonItemGroup,
    IonItemDivider,
    IonNote,
    IonRadioGroup,
    IonRadio,
    IonIcon,
    IonToast,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonToggle,
    IonChip,
    IonAlert,
    IonSpinner,
    IonSkeletonText, IonModal, IonBadge
} from '@ionic/react';
import {RouteComponentProps} from "react-router";
import {
    AddressType,
    CheckoutType,
    ShippingMethodType,
    ProgrammedHourType,
} from "../../lib/data_types/dataTypes"
import {money} from '../../lib/money/money';

//components
import GradientDivider from "../../components/ui/GradientDivider";
import API from "../../lib/api/NbioApi";
import Cart from "../../lib/cart/Cart";
import {calculator, card, cash, pencil} from "ionicons/icons";
import NbioApi from "../../lib/api/NbioApi";
import {Swiper, SwiperSlide} from 'swiper/react';
import WebHeader from "../../components/ui/WebHeader";
import CouponsComponent from "../../components/coupons/CouponsComponent";

//slide style
import 'swiper/swiper-bundle.min.css';
import '@ionic/react/css/ionic-swiper.css';

const _address = {
    addressId: '',
    _id: '-1',
    name: '',
    lastName: '',
    phone: '',
    address: '',
    address2: '',
    zipCode: '',
    reference: '',
    borough: '',
    isDefault: true
}
// PaymentActions
const PAYMENT_ACTIONS = [
    {
        'paymentMethod': 'card',
        'action': 'PAGAR'
    },
    {
        'paymentMethod': 'mercado-pago',
        'action': 'PAGAR'
    },
    {
        'paymentMethod': 'pos',
        'action': 'ORDENAR'
    },
    {
        'paymentMethod': 'cash',
        'action': 'ORDENAR'
    }
]

//Interfaces
interface ConfirmOrderState {
    address: AddressType;
    shippingMethod: ShippingMethodType | null;
    shippingMethodId: string | null;
    paymentMethod: string;
    subtotal: number;
    shippingMethods: ShippingMethodType[];
    checkout: CheckoutType;
    showToast: boolean,
    toastMsg: string,
    programmedHours: ProgrammedHourType[];
    selectedProgrammedHour: ProgrammedHourType | null;
    recommendedGratuityValues: number[];
    selectedGratuityValue: number | string;
    customGratuityValue: number;
    gratuityAlertIsOpen:boolean;
    gratuityToastIsOpen:boolean;
    isLoadingShippingMethods:boolean;
    isOpenCouponModal: boolean;
    coupons: any,
    isPutOrderButtonDisabled: boolean;
    notInServiceableArea: boolean;
    availablePaymentMethods: any;
}

interface ConfirmOrderData {
    addressId: string
}

interface SelectInvoiceData {
    invoiceId: string
}

interface ConfirmOrderProps extends RouteComponentProps {
    addressSelected?: string
}

class ConfirmOrder extends React.Component<ConfirmOrderProps, ConfirmOrderState> {
    constructor(props: ConfirmOrderProps) {
        super(props);
        this.state = {
            address: _address,
            shippingMethod: null,
            shippingMethodId: null,
            paymentMethod: '',
            subtotal: 0.0,
            shippingMethods: [],
            checkout: {
                _id: '',
                checkoutId: '',
                shippingMethod: '',
                cart: [],
                contact: {},
                couponCode: '',
                paymentMethod: '',
                stripePaymentIntentId: '',
                totals: {
                    total: 0,
                    parsedTotal: 0,
                    totalDiscount: 0,
                    parsedTotalDiscount: 0,
                    totalShipping: 0,
                    parsedTotalShipping: 0,
                    subTotal: 0,
                    parsedSubTotal: 0,
                    taxRate: 0,
                    parsedTaxRate: 0,
                    totalTax: 0,
                    parsedTotalTax: 0,
                    gratuityTotal: 0
                },
                shipping: {},
                invoiceData: {
                    cfdiUsage: '',
                    _id: '',
                    address: '',
                    email: '',
                    fiscalRegime: '',
                    id: '',
                    invoiceId: '',
                    isDefault: false,
                    name: '',
                    rfc: ''
                },
                requiresInvoice: false
            },
            showToast: false,
            toastMsg: '',
            programmedHours: [],
            selectedProgrammedHour: null,
            recommendedGratuityValues: [
            ],
            selectedGratuityValue: '',
            customGratuityValue: 0,
            gratuityAlertIsOpen:false,
            gratuityToastIsOpen:false,
            isLoadingShippingMethods:true,
            isOpenCouponModal:false,
            coupons:[],
            isPutOrderButtonDisabled:true,
            notInServiceableArea:false,
            availablePaymentMethods: []
        }
    }
    setPutOrderButton(state:boolean){
        return new Promise((resolve,reject) =>{
            this.setState({isPutOrderButtonDisabled:state}, () =>{
                resolve(true);
            });
        });
    }
    doSetState(args:any){
        return new Promise((resolve,reject) =>{
            // @ts-ignore
            this.setState({...args}, () =>{
                resolve(true);
            });
        });
    }
    trackGTag = async (checkout:any) =>{
        // fb tracking
        try{
            // @ts-ignore
            fbq('track', 'InitiateCheckout',{
                currency: "MXN",
                value:checkout?.totals?.total,
                content_ids: checkout?.cart?.items?.map((item:any) =>{ return item._id}),
                contents: checkout?.cart?.items?.map((item:any) =>{
                    return {
                        item_id: item._id,
                        item_name: item.description,
                        item_brand: item.brandName || '',
                        item_category: item.categories ? item.categories[0] : '',
                        price: item.price,
                        quantity: item.quantity
                    }
                })
            });
        }catch(ex){

        }
        //google tracking
        try{
            // @ts-ignore
            gtag("event", "begin_checkout", {
                currency: "MXN",
                value:checkout?.totals?.total,
                coupon:null,
                items: checkout?.cart?.items?.map((item:any) =>{
                    return {
                        item_id: item._id,
                        item_name: item.description,
                        item_brand: item.brandName || '',
                        item_category: item.categories ? item.categories[0] : '',
                        price: item.price,
                        quantity: item.quantity
                    }
                })

            });
        }catch(ex){

        }
    }
    trackPaymentMethodTag = async (checkout:any,paymentMethod:string) =>{
        // fb tracking
        try{
            // @ts-ignore
            fbq('track', 'AddPaymentInfo',{
                currency: "MXN",
                value:paymentMethod,
                content_ids: checkout?.cart?.items?.map((item:any) =>{ return item._id}),
                contents: checkout?.cart?.items?.map((item:any) =>{
                    return {
                        item_id: item._id,
                        item_name: item.description,
                        item_brand: item.brandName || '',
                        item_category: item.categories ? item.categories[0] : '',
                        price: item.price,
                        quantity: item.quantity
                    }
                })
            });
        }catch(ex){

        }
        //google tracking
        try{
            // @ts-ignore
            gtag("event", "add_payment_info", {
                currency: "MXN",
                value:checkout?.totals?.total,
                coupon:null,
                payment_type:paymentMethod,
                items: checkout?.cart?.items?.map((item:any) =>{
                    return {
                        item_id: item._id,
                        item_name: item.description,
                        item_brand: item.brandName || '',
                        item_category: item.categories ? item.categories[0] : '',
                        price: item.price,
                        quantity: item.quantity
                    }
                })

            });
        }catch(ex){

        }
    }
    async loadAll(){
        // Create/Recover checkout
        await this.setPutOrderButton(true);
        let cartId = Cart.getCartId() || '';
        try{
            const res = await NbioApi.checkout.createCheckout(cartId);
            const checkout = res.data.checkout;
            const shippingMethod = checkout.shippingMethod;
            const paymentMethod = res.data.checkout.paymentMethod;
            this.trackGTag(res?.data?.checkout);
            await this.doSetState({shippingMethod, paymentMethod, checkout});
            await this.loadAddress();
            await this.loadShippingMethods();
            await this.calcGratuity(true);
            await this.loadTotals();
            await this.loadInvoice();
        }catch(ex){

        };
        try{
            const res = await NbioApi.shippingMethods.getDeliveryBlocks();
            let blocks = res.data.blocks.map((b: any) => b.blocks);
            blocks = [].concat.apply([], blocks)
            await this.doSetProgrammedHours(blocks);
        }catch(ex){

        }
        this.loadCoupons();
        await this.setPutOrderButton(false);
        await this.loadAvailablePaymentMethods();
    }
    async doSetProgrammedHours(programmedHours: any){
        return new Promise((resolve,reject) =>{
            this.setState({programmedHours}, () =>{
                resolve(true);
            });
        });
    }
    ionViewDidEnter() {
        this.loadAll().then((res) =>{

        }).catch((ex) =>{

        });
    }
    async doSetGratuity(recommendedGratuityValues:any){
        return new Promise((resolve,reject) =>{
            this.setState({recommendedGratuityValues}, () =>{
                resolve(true);
            });
        });
    }
    async doSetGratuityValue(selectedGratuityValue:any){
        return new Promise((resolve,reject) =>{
            this.setState({selectedGratuityValue}, () =>{
                resolve(true);
            });
        });
    }
    async calcGratuity(preselect = false) {
        // 10%, 15%, 20%
        const recommendedGratuityValues = [
            0,
            30,
            40
        ];
        await this.doSetGratuity(recommendedGratuityValues);
        if(preselect){
            await this.doSetGratuityValue(recommendedGratuityValues[0]);
        }
    }

    async updateGratuity() {
        try{
            const amount = this.state.selectedGratuityValue === 'other' ?
                this.state.customGratuityValue : parseFloat(this.state.selectedGratuityValue.toString());
            await NbioApi.checkout.setGratuity(this.state.checkout.checkoutId, 'fixed', amount);
            await this.updateCheckout();
        }catch(ex){
        }
    }

    async updateCheckout() {
        let newCheckout = {
            shippingMethod: this.state.shippingMethodId,
            paymentMethod: this.state.paymentMethod,
            contact: {
                phone: this.state.address.phone,
            },
            billing: {},
            shipping: this.state.address,
            deliveryBlock: this.state.shippingMethod?.shippingType === 'schedule' ? this.state.selectedProgrammedHour : {},
            requiresInvoice: this.state.checkout.requiresInvoice,
            invoiceData: this.state.checkout.invoiceData
        }
        try{
            const res = await NbioApi.checkout.updateCheckout(newCheckout, this.state.checkout.checkoutId);
            await this.doSetCheckout(res.data.checkout);
            await this.calcGratuity();
        }catch(ex){

        }
    }

    goToAddresses() {
        this.props.history.push({
            pathname: '/direcciones',
            state: {
                goBackTo:'/confirmar-pedido',
            }
        });
    }

    goToInvoices() {
        this.props.history.push({
            pathname: '/datos-de-facturacion',
            state: {
                onSelectedPath: '/confirmar-pedido'
            }
        });
    }
    async doSetTotals(subTotal:any){
        return new Promise((resolve,reject) =>{
            // @ts-ignore
            this.setState({subTotal}, () =>{
                resolve(true);
            });
        });
    }
    async loadTotals() {
        try{
            const res = await  Cart.getCart();
            let parsedTotalItems = res.data.parsedTotalItems;
            await this.doSetTotals(parsedTotalItems);
        }catch(ex){
        }
    }
    async doSetCheckout(checkout:any){
        return new Promise((resolve,reject) =>{
            // @ts-ignore
            this.setState({checkout}, () =>{
                resolve(true);
            });
        });
    }
    async loadInvoice() {
        let i = this.props.location.state as SelectInvoiceData;
        // undefined OK
        // {invoiceId}
        // {addressId}
        const invoiceId = i ? i.invoiceId ? i.invoiceId : null : null;
        if (invoiceId) {
            // load this invoice ID (When the user picks a new invoice)
            try{
                const res = await API.invoices.getInvoice(i.invoiceId)
                    const checkout = {...this.state.checkout}
                    checkout.invoiceData = res.data.invoice;
                    await this.doSetCheckout(checkout);
                    // Update checkout with this invoice
                    await this.updateCheckout();
            }catch(ex){
            }
        } else {
            // Now check if the checkout already has an invoice
            if (this.state.checkout.invoiceData?.invoiceId) {
                try{
                    const res = await API.invoices.getInvoice(this.state.checkout.invoiceData.invoiceId);
                    const checkout = {...this.state.checkout}
                    checkout.invoiceData = res.data.invoice;
                    await this.doSetCheckout(checkout);
                    // Update checkout with this invoice
                    await this.updateCheckout();
                }catch(ex){
                }

            } else {
                // load default invoice
                try{
                    const res = await API.invoices.getDefault();
                    const checkout = {...this.state.checkout}
                    checkout.invoiceData = res.data.invoice
                    await this.doSetCheckout(checkout);
                    // Update checkout with this invoice
                    await this.updateCheckout();
                }catch(ex){
                }
            }
        }
    }
    async loadAvailablePaymentMethods(){
        try{
            const res = await API.store.getAvailablePaymentMethods();
            // Compare regions payment methods with general payment methods
            let availablePaymentMethods = res.data.paymentMethods;
            if(this.state.checkout?.region?.paymentMethods){
                const rpm = this.state.checkout?.region?.paymentMethods;
                availablePaymentMethods = availablePaymentMethods.map((apm:any) =>{
                    apm.isActive = apm.isActive && (rpm.find((pm) => pm.paymentMethod === apm._id))?.isActive;
                    return apm;
                });
            }
            this.setState({availablePaymentMethods: availablePaymentMethods});
            // console.log('res',res);
        }catch (e) {
            // console.log('ex',e);
        }
    }
    async doSetIsLoadingShippingMethods(isLoadingShippingMethods:any){
        return new Promise((resolve,reject) =>{
            this.setState({isLoadingShippingMethods}, () =>{
                resolve(true);
            });
        });
    }
    async doSetShippingMethods(shippingMethods:any,shippingMethodId:string){
        return new Promise((resolve,reject) =>{
            this.setState({shippingMethods,shippingMethodId}, () =>{
                resolve(true);
            });
        });
    }
    async loadShippingMethods(){
        // Fetch shipping methods
        await this.doSetIsLoadingShippingMethods(true);
        await this.setState({notInServiceableArea:false});
        try{
            const res = await  NbioApi.shippingMethods.getShippingMethods(this.state.checkout.checkoutId);
            // Always reset to first shipping Method
            const selectedShippingId = res.data.shippingMethods.length > 0 ? res.data.shippingMethods[0]._id : null;
            if(res.data.shippingMethods.length === 0){
                await this.setState({notInServiceableArea:true});
            }else{
                // Update shipping method
                const shippingMethod = res.data.shippingMethods.find((sm:any) =>  sm._id.toString() === selectedShippingId);
                if(shippingMethod){
                    await this.doSetState({shippingMethod});
                }
            }
            await this.doSetShippingMethods(res.data.shippingMethods,selectedShippingId);
            await this.updateCheckout()
            await this.loadTotals();
            await this.doSetIsLoadingShippingMethods(false);
        }catch(ex){
            await this.doSetIsLoadingShippingMethods(false);
            return ex;
        }
    }
    async doSetStateAddress(address:any){
        return new Promise((resolve,reject) =>{
            this.setState({address}, () =>{
               resolve(true);
            });
        });
    }
    async loadAddress() {
            let i = this.props.location.state as ConfirmOrderData;
            // undefined OK
            // {invoiceId}
            // {addressId}
            const addressId = i ? i.addressId ? i.addressId : null : null;
            if (addressId) {
                // load this address ID (When the user picks a new address)
                try{
                    const res = await API.addresses.getAddress(i.addressId);
                    await this.doSetStateAddress(res.data.address);
                    // Update checkout with this address
                    await this.updateCheckout();
                }catch(ex){
                    await this.doSetStateAddress(_address);
                }
            } else {
                // Now check if the checkout already has an address
                if (this.state.checkout.shipping.addressId) {
                    try{
                        const res = await API.addresses.getAddress(this.state.checkout.shipping.addressId);
                        await this.doSetStateAddress(res.data.address);
                        // Update checkout with this address
                        await this.updateCheckout();
                    }catch(ex){
                        await this.doSetStateAddress(_address);
                    }
                } else {
                    try{
                        const res = await API.addresses.getDefault();
                        await this.doSetStateAddress(res.data.address);
                        // Update checkout with this address
                        await this.updateCheckout();
                    }catch(ex){
                        await this.doSetStateAddress(_address);
                    }
                }
            }
    }
    renderAddress(address: any) {
        if (this.state.address._id === '-1') {
            return (<IonLabel>Selecciona una dirección</IonLabel>)
        } else {
            return (<div><IonLabel>{address.name} {address.lastName}
                <small>&nbsp;{address.phone}</small></IonLabel>
                <IonNote>{address.address}, {address.borough}, {address.zipCode}</IonNote></div>)
        }
    }

    renderInvoice(invoice: any) {
        if (!invoice) {
            return (<IonLabel>Selecciona los datos de facturación</IonLabel>)
        } else if (!invoice.invoiceId) {
            return (<IonLabel>Selecciona los datos de facturación</IonLabel>)
        } else {
            return (<div><IonLabel>{invoice.rfc}
                <small>&nbsp;</small></IonLabel>
                <IonNote>{invoice.name}</IonNote></div>)
        }
    }

    renderNotePOS = (paymentMethod: string) => {
        if (paymentMethod === 'pos') {
            return <IonNote>&nbsp;Realizarás tu pago a través de una terminal punto de venta con el repartidor.</IonNote>
        } else {
            return null
        }
    }

    renderNoteCash = (paymentMethod: string) => {
        if (paymentMethod === 'cash') {
            return <IonNote>&nbsp;Realizarás tu pago con el repartidor.</IonNote>
        } else {
            return null
        }
    }

    onClickPlaceOrder = () => {
        const {paymentMethod} = this.state;
        const {address} = this.state;
        const {invoiceData} = this.state.checkout;
        const {requiresInvoice} = this.state.checkout;
        const {shippingMethod} = this.state.checkout;
        if (requiresInvoice) {
            if (!invoiceData?.invoiceId) {
                this.setState({showToast: true, toastMsg: 'Selecciona tus datos de facturación'})
                return null;
            }

        }
        if (this.state.shippingMethod?.shippingType === 'schedule') {
            if (!this.state.selectedProgrammedHour) {
                this.setState({showToast: true, toastMsg: 'Selecciona un horario de entrega'})
                return null;
            }
        }

        if (address._id !== '-1') {
            // @ts-ignore
            let action = PAYMENT_ACTIONS
                .find((p) => p.paymentMethod === paymentMethod)
            let actionTxt = ' ';
            if (action) {
                actionTxt = action.action;
            }
            NbioApi.checkout.check(this.state.checkout.checkoutId).then((res) =>{
                // CHECKOUT CHECK
                this.props.history.push({
                    pathname: `/pagar`,
                    state: {
                        paymentType: paymentMethod,
                        checkoutId: this.state.checkout.checkoutId,
                        action: actionTxt
                    }
                })
            }).catch((ex) =>{
                let errorMsg = 'Hubo un problema validando tu orden.';
                try{
                    errorMsg = ex.response.data.error_es;
                }catch(ex){

                }
                this.setState({showToast:true,toastMsg:errorMsg});
            })


        } else {
            this.setState({showToast: true, toastMsg: 'Selecciona una dirección para continuar con la compra.'})
        }
        if(!shippingMethod){
            this.setState({showToast: true, toastMsg: 'Selecciona un tipo de entrega para continuar con la compra.'})
        }
    }
    async onRenderTimeBlockSelected(selectedProgrammedHour: any){
        await this.setPutOrderButton(true);
        await this.doSetState({selectedProgrammedHour});
        await this.updateCheckout();
        await this.setPutOrderButton(false);
    }
    renderTimeBlocks(ph: ProgrammedHourType) {
        return (
            <IonCard color={this.state.selectedProgrammedHour?.id === ph.id ? 'primary' : ''}
                     onClick={() => {
                         this.onRenderTimeBlockSelected(ph);
                     }}>
                <IonCardHeader>
                    {ph.shortDayLabel}
                </IonCardHeader>
                <IonCardContent>
                    {ph.hoursLabel}
                </IonCardContent>
            </IonCard>
        )
    }
    renderShippingMethodPrice(shippingMethod: ShippingMethodType){
        if(shippingMethod.oldPrice){
            return <div className={'flex-row'}>
                <IonLabel color={'danger'} className={'mr-2'}>
                    <b>GRATIS</b>
                </IonLabel>
                <s>{money(shippingMethod.price)}</s>
            </div>
        }else{
            return `${money(shippingMethod.price)}`;
        }
    }
    render() {
        const {shippingMethodId} = this.state;
        const {paymentMethod} = this.state;
        const checkout = this.state.checkout;
        const checkoutCoupons = checkout.cart.coupons || [];
        return (
            <IonPage>
                <WebHeader title={'Confirmar pedido'} showSearchButton={false} showCartButton={false}></WebHeader>
                <IonContent fullscreen>
                    <IonToast isOpen={this.state.showToast}
                              onDidDismiss={() => this.setState({showToast: false, toastMsg: ''})}
                              message={this.state.toastMsg}
                              duration={4000}/>
                    <IonGrid className={'ion-no-padding'}>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size-md="6">
                                <IonItemGroup>
                                    <IonItemDivider>
                                        <IonLabel>Dirección de envío</IonLabel>
                                    </IonItemDivider>

                                    <IonItem lines={"none"} onClick={() => this.goToAddresses()}
                                             detail>
                                        {this.renderAddress(this.state.address)}
                                    </IonItem>
                                </IonItemGroup>

                                <IonItemGroup className={'ion-margin-top'}>
                                    <IonItemDivider>
                                        <IonLabel>Facturación</IonLabel>
                                    </IonItemDivider>
                                    <IonItem lines={"none"}>
                                        <IonLabel slot="start">¿Necesitas factura?</IonLabel>
                                        <IonToggle slot="end" checked={this.state.checkout.requiresInvoice}
                                                   onIonChange={(e) => {
                                                       const checkout = {...this.state.checkout};
                                                       checkout.requiresInvoice = e.detail.checked;
                                                       this.setState({checkout: checkout}, () => {
                                                           this.updateCheckout();
                                                       })
                                                   }}/>
                                    </IonItem>
                                    {
                                        this.state.checkout.requiresInvoice ?
                                            <IonItem lines={"none"} onClick={() => this.goToInvoices()}
                                                     detail>
                                                {this.renderInvoice(this.state.checkout.invoiceData)}
                                            </IonItem> : null
                                    }

                                </IonItemGroup>

                                <IonItemGroup className={"ion-margin-top"}>
                                    <IonItemDivider>
                                        <IonLabel>¿Qué tipo de entrega necesitas?</IonLabel>
                                    </IonItemDivider>

                                    {
                                        this.state.isLoadingShippingMethods ?
                                            <IonSkeletonText animated={true} style={{height:130}}>

                                            </IonSkeletonText>
                                            :
                                            <>
                                                {
                                                    this.state.notInServiceableArea ?
                                                        <IonCard color="danger" className={'ion-margin'}>
                                                            <IonLabel className="ion-text-wrap padding-half">
                                                                Lo sentimos, la dirección seleccionada no se encuentra
                                                                en la cobertura de Nbio. Selecciona otra dirección
                                                                para mostrar las opciones.
                                                            </IonLabel>
                                                        </IonCard>
                                                        : null
                                                }
                                                <IonRadioGroup value={shippingMethodId}
                                                               name={'shipping-method-radio-group'}
                                                               onIonChange={e => this.onShippingMethodChanged(e)}>
                                                    {
                                                        this.state.shippingMethods.map((shippingMethod) => {
                                                            return (
                                                                <IonItem lines={"none"} key={shippingMethod._id}
                                                                         className={
                                                                             shippingMethod._id === this.state.shippingMethodId ?
                                                                                 'selected-item-border' : ''
                                                                         }>
                                                                    <IonRadio slot="start" value={shippingMethod._id}></IonRadio>
                                                                    <div>
                                                                        <div className={'flex-row ion-justify-content-between'}>
                                                                            <IonLabel>
                                                                                {shippingMethod.name}
                                                                            </IonLabel>

                                                                        </div>
                                                                    </div>
                                                                    <IonLabel className={''} slot={'end'}>
                                                                        {this.renderShippingMethodPrice(shippingMethod)}
                                                                    </IonLabel>
                                                                </IonItem>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        this.state.shippingMethod?.shippingType === 'schedule' ?
                                                            <>
                                                                <IonItemDivider>
                                                                    <IonLabel class={'ion-text-wrap'}>Selecciona el horario en el que quieres recibir tu pedido:</IonLabel>
                                                                </IonItemDivider>
                                                                <Swiper slidesPerView={2.1} loop={false}>
                                                                    {
                                                                        this.state.programmedHours.map((ph, i) => {
                                                                            return (
                                                                                <SwiperSlide key={i}>
                                                                                    {
                                                                                        this.renderTimeBlocks(ph)
                                                                                    }
                                                                                </SwiperSlide>
                                                                            )
                                                                        })
                                                                    }

                                                                </Swiper>
                                                            </>
                                                            : null
                                                    }
                                                </IonRadioGroup>
                                            </>

                                    }
                                </IonItemGroup>

                                <IonItemGroup className={"ion-margin-top"}>
                                    <IonItemDivider>
                                        <IonLabel>Método de pago</IonLabel>
                                    </IonItemDivider>

                                    <IonRadioGroup value={paymentMethod}
                                                   name={'payment-method-radio-group'}
                                                   onIonChange={e => {
                                                       this.onPaymentMethodChanged(e);
                                                   }}>
                                        <IonItem lines={"none"}>
                                            <IonRadio slot="start" value={'mercado-pago'} disabled={this.isPaymentMethodDisabled('mercado-pago')}></IonRadio>
                                            <IonIcon icon={card} color={'primary'}></IonIcon>
                                            <IonLabel>
                                                &nbsp;Tarjeta de crédito / débito
                                            </IonLabel>
                                        </IonItem>
                                        <IonItem lines={"none"}>
                                            <IonRadio slot="start" value={'pos'} disabled={this.isPaymentMethodDisabled('pos')}></IonRadio>
                                            <IonIcon icon={calculator} color={'primary'}></IonIcon>
                                            <div>
                                                <IonLabel>&nbsp;Pago con Terminal</IonLabel>
                                                {this.renderNotePOS(paymentMethod)}
                                            </div>
                                        </IonItem>
                                        <IonItem lines={"none"}>
                                            <IonRadio slot="start" value={'cash'} disabled={this.isPaymentMethodDisabled('cash')}></IonRadio>
                                            <IonIcon icon={cash} color={'primary'}></IonIcon>
                                            <div>
                                                <IonLabel>&nbsp;Efectivo</IonLabel>
                                                {this.renderNoteCash(paymentMethod)}
                                            </div>
                                        </IonItem>
                                    </IonRadioGroup>
                                </IonItemGroup>
                                {
                                    this.state.paymentMethod !== 'mercado-pago' ? null :
                                        <IonItemGroup className={"ion-margin-top"}>
                                            <IonItemDivider>
                                                <IonLabel>Propina</IonLabel>
                                            </IonItemDivider>
                                            <div className={'ion-padding'}>
                                                <Swiper  slidesPerView={4}>

                                                    {
                                                        this.state.recommendedGratuityValues.map((rgv, index) => {
                                                            const isSelected = rgv === this.state.selectedGratuityValue;
                                                            return (
                                                                <SwiperSlide >
                                                                    <IonChip color={isSelected ? 'primary' : ''}
                                                                             onClick={() => {
                                                                                 this.onGratuitySelected(rgv);
                                                                             }}>
                                                                        <IonLabel>
                                                                            {money(rgv)}
                                                                        </IonLabel>
                                                                    </IonChip>
                                                                </SwiperSlide>
                                                            )
                                                        })
                                                    }
                                                    <SwiperSlide>
                                                        <IonChip color={this.state.selectedGratuityValue ==='other' ? 'primary' : ''} onClick={() =>{
                                                            this.setState({
                                                                gratuityAlertIsOpen:true
                                                            })
                                                        }}>
                                                            <IonIcon icon={pencil} color={'secondary'}>

                                                            </IonIcon>
                                                            <IonLabel>
                                                                {this.state.selectedGratuityValue === 'other'? money(this.state.customGratuityValue) : 'Editar'}
                                                            </IonLabel>
                                                        </IonChip>
                                                    </SwiperSlide>
                                                </Swiper>


                                            </div>

                                            {/*<IonItem lines={"none"}>*/}
                                            {/*    <IonInput id="gratuity_other_amount"*/}
                                            {/*              placeholder="Otra Cantidad"*/}
                                            {/*              maxlength={5}*/}
                                            {/*              disabled={this.state.selectedGratuityValue !== 'other'}*/}
                                            {/*              onIonBlur={() =>{*/}
                                            {/*              }}*/}
                                            {/*              value={this.state.customGratuityValue}*/}
                                            {/*              inputMode={'numeric'}*/}
                                            {/*              clearInput={true}*/}
                                            {/*              onIonChange={(e: any) => {*/}
                                            {/*                  e.preventDefault();*/}
                                            {/*                  e.stopPropagation();*/}
                                            {/*                  let v = e.detail.value.toString().replace(/[^0-9]/g,'');*/}
                                            {/*                  if(!v){*/}
                                            {/*                      v = '0'*/}
                                            {/*                  }*/}
                                            {/*                  this.setState({*/}
                                            {/*                      customGratuityValue: v,*/}
                                            {/*                      selectedGratuityValue:'other'*/}
                                            {/*                  }, () => {*/}
                                            {/*                      this.updateGratuity();*/}
                                            {/*                  });*/}
                                            {/*              }}>*/}
                                            {/*    </IonInput>*/}
                                            {/*</IonItem>*/}
                                        </IonItemGroup>
                                }

                                <IonItemGroup className={"ion-margin-top"}>
                                    <IonItemDivider>
                                        <IonLabel>Cupones</IonLabel>
                                    </IonItemDivider>
                                    <IonItem lines={"none"} detail button
                                             onClick={() => {this.setState({isOpenCouponModal:true})}}>
                                        <IonLabel >
                                           Agregar un cupón
                                        </IonLabel>
                                        {
                                            this.state.coupons.length > 0 ? <IonBadge color={'danger'}>{this.state.coupons.length}</IonBadge> : null
                                        }
                                    </IonItem>
                                    {
                                        checkoutCoupons.map((coupon:any) =>{
                                            return(
                                                <IonItem lines={'full'} >
                                                    <IonLabel slot={'start'} color={'primary'}>
                                                        {coupon.code}
                                                    </IonLabel>
                                                    <IonButton color={'danger'} fill={'outline'} slot={'end'}
                                                               onClick={() => this.removeCoupon(coupon)}>
                                                        Remover
                                                    </IonButton>
                                                </IonItem>
                                            );
                                        })
                                    }
                                </IonItemGroup>

                                <IonItemGroup className={"ion-margin-top"}>
                                    <IonItemDivider>
                                        <IonLabel>Resumen</IonLabel>
                                    </IonItemDivider>
                                    <IonItem lines={"none"}>
                                        <IonLabel className={'ion-text-uppercase'}>SUBTOTAL</IonLabel>
                                        <IonLabel slot={"end"}>{money(this.state.checkout.totals.subTotal)}</IonLabel>
                                    </IonItem>
                                    <IonItem lines={"none"}>
                                        <IonLabel className={'ion-text-uppercase'}>IMPUESTOS</IonLabel>
                                        <IonLabel slot={"end"}>{money(this.state.checkout.totals.totalTax)}</IonLabel>
                                    </IonItem>
                                    <IonItem lines={"none"}>
                                        <IonLabel className={'ion-text-uppercase'}>ENVÍO</IonLabel>

                                        {
                                            this.state.checkout?.freeShipping?.hasFreeShipping ?
                                                <div className={'flex-row'}>
                                                    <IonLabel color={'danger'} className={'mr-2'}>
                                                        <b>GRATIS</b>
                                                    </IonLabel>
                                                    <s>{money(this.state?.shippingMethod?.oldPrice)}</s>
                                                </div> :
                                                <IonLabel
                                                    slot={"end"}>{money(this.state.checkout.totals.totalShipping)}</IonLabel>
                                        }
                                    </IonItem>
                                    {
                                        this.state.checkout.totals.totalDiscount ?
                                            <IonItem lines={"none"}>
                                                <IonLabel className={'ion-text-uppercase'}>DESCUENTOS</IonLabel>
                                                <IonLabel color={'danger'}
                                                          slot={"end"}>-{money(this.state.checkout.totals.totalDiscount)}</IonLabel>
                                            </IonItem> : null
                                    }
                                    <IonItem lines={"none"}>
                                        <IonLabel className={'ion-text-uppercase'}>PROPINA</IonLabel>
                                        <IonLabel
                                            slot={"end"}>{money(this.state.checkout.totals.gratuityTotal)}</IonLabel>
                                    </IonItem>
                                </IonItemGroup>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
                <IonFooter>
                    <IonToolbar>
                        <IonGrid>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size-md="6">
                                    <div className={'flex-row ion-justify-content-between margin-bottom-half'}>
                                        <IonLabel className={'ion-text-uppercase'}>TOTAL</IonLabel>
                                        <IonLabel
                                            className={'ion-text-uppercase'}>{money(this.state.checkout.totals.total)}</IonLabel>
                                    </div>

                                    <IonButtons className={'margin-top ion-align-items-center flex-row ion-justify-content-center'}>
                                        {
                                            this.state.isPutOrderButtonDisabled ?
                                                <IonSpinner name={'dots'} ></IonSpinner>
                                                :
                                                <IonButton className="w-100"
                                                           color="primary"
                                                           expand="block"
                                                           fill='solid'
                                                           onClick={() => this.onClickPlaceOrder()}>
                                                    Realizar el pedido
                                                </IonButton>
                                        }

                                    </IonButtons>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonToolbar>
                </IonFooter>
                <IonToast
                    message={'Ingresa una cantidad válida para la propina'}
                    isOpen={this.state.gratuityToastIsOpen}
                    duration={1000}
                    onDidDismiss={() => this.setState({gratuityToastIsOpen:true})}
                />
                <IonAlert
                    isOpen={this.state.gratuityAlertIsOpen}
                    onDidDismiss={() => {}}
                    cssClass='my-custom-class'
                    header={'Propina'}
                    message={'Ingresa una cantidad'}
                    buttons={[
                        {
                            text: 'Cancelar',
                            role: 'cancel',
                            cssClass: 'secondary',
                            id: 'cancel-button',
                            handler: blah => {
                                this.setState({
                                    gratuityAlertIsOpen:false
                                })
                            }
                        },
                        {
                            text: 'Aceptar',
                            id: 'confirm-button',
                            handler: (v) => {
                                if(v.amount){
                                    if(v.amount >= 0 && v.amount <= 100000){
                                        this.setState({
                                            customGratuityValue:parseFloat(v.amount),
                                            selectedGratuityValue:'other'
                                        }, async() =>{
                                            await this.setPutOrderButton(true);
                                            await this.updateGratuity();
                                            await this.setPutOrderButton(false);

                                        })
                                    }else{
                                        this.setState({gratuityToastIsOpen:true})
                                    }
                                }else{
                                    this.setState({gratuityToastIsOpen:true})
                                }
                                this.setState({
                                    gratuityAlertIsOpen:false
                                })
                            }
                        }
                    ]}
                    inputs={[
                        {
                            name: 'amount',
                            type: 'number',
                            placeholder: 'Cantidad'
                        }
                    ]
                    }
                />
                <IonModal isOpen={this.state.isOpenCouponModal} backdropDismiss={false}>
                    <CouponsComponent coupons={this.state.coupons}
                                      onClickClose={() => {this.setState({isOpenCouponModal:false})}}
                                      onCouponClicked={(couponId:string) => this.onAddCoupon(couponId)}
                    />
                </IonModal>


            </IonPage>
        )
    }

    loadCoupons() {
        NbioApi.coupons.getMyCoupons().then((res) =>{
            this.setState({coupons:res.data.coupons.map((c:any) => c.coupon)});
        }).catch((ex) =>{
            console.log(ex);
        })
    }

    async onAddCoupon(couponId: string) {
        await this.setPutOrderButton(true);

        try {
            await Cart.addCoupon(couponId);
            this.setState({isOpenCouponModal:false});
            await this.updateCheckout();
        }catch(ex:any){
            let errorMsg = 'Hubo un error al agregar el cupón';
            try {
                errorMsg = ex.response.data.error_es;

            } catch (ex) {
            }
            this.setState({showToast: true, toastMsg: errorMsg});
        }
        await this.setPutOrderButton(false);
    }

    async removeCoupon(coupon: any) {
        await this.setPutOrderButton(true);
        try {
            await Cart.removeCoupon(coupon._id);
            this.setState({isOpenCouponModal:false});
            await this.updateCheckout();
        }catch(ex:any){
            let errorMsg = 'Hubo un error al remover el cupón';
            try {
                errorMsg = ex.response.data.error_es;

            } catch (ex) {
            }
            this.setState({showToast: true, toastMsg: errorMsg});
        }
        await this.setPutOrderButton(false);
    }

    async onShippingMethodChanged(e:any) {
        await this.setPutOrderButton(true);
        const shippingMethod =
            this.state.shippingMethods.find(
                (sm) =>
                    sm._id.toString() === e.detail.value
            );
        const data = {
            shippingMethod: shippingMethod || null,
            shippingMethodId: e.detail.value
        }
        await this.doSetState(data);
        await this.updateCheckout();
        await this.setPutOrderButton(false);
        // @ts-ignore
        trackShippingMethod(selectedShippingMethod?.name);
    }
    async trackShippingMethod(shippingMethod:string){
        // fb tracking
        try{
            // @ts-ignore
            fbq('track', 'ShippingMethod',{
                currency: "MXN",
                shippingMethod:shippingMethod
            });
        }catch(ex){

        }
        //google tracking
        try{
            // @ts-ignore
            gtag("event", "shippingMethod", {
                shippingMethod:shippingMethod
            });
        }catch(ex){

        }
    }
    async onPaymentMethodChanged(e:any) {
        const value = e.detail.value;
        await this.setPutOrderButton(true);
        if(value === this.state.paymentMethod){
            // Avoid multiple calls
            return true;
        }
        if(value !== 'mercado-pago') {
            const data = {
                customGratuityValue: 0,
                selectedGratuityValue: 0,
                paymentMethod:value
            }
            await this.doSetState(data);
            await this.updateGratuity();
        }else{
            const paymentMethod = {
                paymentMethod:value
            };
            await this.doSetState(paymentMethod);
            await this.calcGratuity(true);
            await this.updateGratuity();
        }
        await this.setPutOrderButton(false);
        this.trackPaymentMethodTag(this.state.checkout,value);
    }

    async onGratuitySelected(grv: any) {
        await this.setPutOrderButton(true);
        await this.doSetState({selectedGratuityValue:grv});
        await this.updateGratuity();
        await this.setPutOrderButton(false);
    }

    private isPaymentMethodDisabled(code: string) {
        const paymentMethod = this.state.availablePaymentMethods.find((pm:any) => pm.code === code);
        return !paymentMethod?.isActive;

    }
}

export default withIonLifeCycle(ConfirmOrder);
const shippingMethodsChanged = (prevMethods:any,newMethods:any) => {
    if(prevMethods.length !== newMethods.length){
        return true;
    }
    const areSame = prevMethods.every((el:any,i:number) => {
        return el._id === newMethods[i]._id && el.price === newMethods[i].price
    });
    return !areSame;
}
