/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
 */
import React, {ElementType} from 'react';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonPage,
    withIonLifeCycle,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonBackButton,
    IonFooter,
    IonProgressBar, IonToast
} from '@ionic/react';
import {RouteComponentProps} from "react-router";
import Cart from "../../lib/cart/Cart";

//components
import GradientDivider from "../../components/ui/GradientDivider";
import CashPayment from "../../components/checkout/CashPayment";
import NbioApi from "../../lib/api/NbioApi";
import PosPayment from "../../components/checkout/PosPayment";
import CardPayment from "../../components/checkout/CardPayment";
import API from "../../lib/api/NbioApi";
import WebHeader from "../../components/ui/WebHeader";
import { money } from '../../lib/money/money';
import MPPayment from "../../components/checkout/MPPayment";


type LocationState = {
    paymentType: string,
    checkoutId:string,
    action:string
};

//Interfaces
interface PaymentState {
    total: number;
    paymentType: string;
    isLoading: boolean;
    checkoutId: string;
    isPaying:boolean;
    action:string;
    isToastOpen:boolean;
    checkout?:any;
}

interface PaymentProps extends RouteComponentProps {
}

const PAYMENTS_VIEWS = [
    {
        'type': 'cash',
        'Element': CashPayment
    },
    {
        'type':'saved_card',
        'Element':CardPayment
    },
    {
        'type': 'mercado-pago',
        'Element': MPPayment
    },
    {
        'type': 'pos',
        'Element': PosPayment
    },

]

class Payment extends React.Component<PaymentProps, PaymentState> {
    private paymentElement: any;
    constructor(props: PaymentProps) {
        super(props);
        this.state = {
            total: 0,
            paymentType: '',
            isLoading: true,
            checkoutId:'',
            isPaying:false,
            action:'',
            isToastOpen:false,
            checkout:{}
        }
    }

    ionViewDidEnter() {
        //get payment type from previous page
        const lState = this.props.location.state as LocationState;

        if(lState.paymentType === 'card'){
            // check if the user has a default payment method
            API.paymentMethods.getDefault().then((res) =>{
                if(res.data.defaultMethod){
                    this.setState({
                        paymentType: 'saved_card',
                        isLoading: false,
                        checkoutId:lState.checkoutId,
                        action:lState.action
                    }, () =>{
                        //get total from the cart
                        this.loadTotals();
                    });
                }else{
                    this.setState({
                        paymentType: lState.paymentType,
                        isLoading: false,
                        checkoutId:lState.checkoutId,
                        action:lState.action
                    }, () =>{
                        //get total from the cart
                        this.loadTotals();
                    });
                }

            }).catch((ex) =>{
                console.log(ex)
                // TODO: show error
            })
        }else{
            this.setState({
                paymentType: lState.paymentType,
                isLoading: false,
                checkoutId:lState.checkoutId,
                action:lState.action
            }, () =>{
                //get total from the cart
                this.loadTotals();
            });
        }



    }

    loadTotals = () => {
        NbioApi.checkout.getCheckout(this.state.checkoutId).then((res) =>{
            this.setState({
                total:res.data.checkout.totals.parsedTotal,
                checkout:res.data.checkout
            })
        }).catch((ex) =>{
            this.setState({isToastOpen:true});
        })
    }

    renderPaymentType = (type: string) => {
        let element = PAYMENTS_VIEWS.find((p) => p.type === type);
        if (element) {
            let El = element.Element as ElementType;
            return (
                <El  ref={(ref:any) =>this.paymentElement = ref}
                     checkoutId={this.state.checkoutId}
                     onConfirmPayment={(orderId:string) => this.onConfirmPayment(orderId)}
                     onFailedPayment={(data:any) => this.onFailedPayment(data)}
                     total={this.state.total}
                     onChangePaymentType={(paymentType:string) => this.setState({paymentType:paymentType})}
                >
                </El>
            )
        } else {
            return <div></div>
        }
    }
    renderPayButton(){
        if (this.state.isPaying) {
            return (
                <IonProgressBar type="indeterminate"></IonProgressBar>
            )
        } else {
            return(
                <IonButton className="w-100"
                           color="primary"
                           expand="block"
                           fill='solid'
                           onClick={() => this.onPay()}
                >
                    {this.state.action} ({money(this.state.total)})
                </IonButton>
            )
        }
    }
    render() {
        return (
            <IonPage>
                <WebHeader title={'Pago'} showSearchButton={false} showCartButton={false} showMenuButton={false}></WebHeader>
                {this.renderPaymentType(this.state.paymentType)}
                <IonFooter>
                    <IonToolbar>
                        <IonGrid>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size-md="6">
                                    <IonButtons>
                                        {this.renderPayButton()}
                                    </IonButtons>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonToolbar>
                </IonFooter>
                <IonToast
                    isOpen={this.state.isToastOpen}
                    duration={2000} message={'Hubo un error al inicializar tu pago. Intenta más tarde o actualiza tu aplicación.'}
                    onDidDismiss={() => this.setState({isToastOpen:false})}
                />
            </IonPage>
        )
    }

    onPay() {
        this.setState({isPaying:true},() =>{
            try{
                this.paymentElement.pay();
            }catch(ex){
                console.log('ex',ex);
                this.setState({isPaying:false,isToastOpen:true})
            }
        })
    }

    private async  trackPurchase (checkout:any,orderId:string){
        // fb tracking
        try{
            // @ts-ignore
            fbq('track', 'Purchase',{
                currency: "MXN",
                value:this.state.paymentType,
                content_ids: checkout?.cart?.items?.map((item:any) =>{ return item._id}),
                contents: checkout?.cart?.items?.map((item:any) =>{
                    return {
                        item_id: item._id,
                        item_name: item.description,
                        item_brand: item.brandName || '',
                        item_category: item.categories ? item.categories[0] : '',
                        price: item.price,
                        quantity: item.quantity
                    }
                })
            });
        }catch(ex){

        }
        //google tracking
        try {
            // @ts-ignore
            gtag("event", "purchase", {
                currency: "MXN",
                payment_type: this.state.paymentType,
                value: checkout?.totals?.total,
                coupon: null,
                transaction_id: orderId,
                items: checkout?.cart?.items?.map((item: any) => {
                    return {
                        item_id: item._id,
                        item_name: item.description,
                        item_brand: item.brandName || '',
                        item_category: item.categories ? item.categories[0] : '',
                        price: item.price,
                        quantity: item.quantity
                    }
                })

            });
        } catch (ex) {


        }
    }
    private onConfirmPayment(orderId: string) {
        this.trackPurchase(this.state.checkout,orderId);
        //Facebook Pixel
        try{
            // @ts-ignore
            fbq('track', 'Purchase', {value: this.state.total, currency: 'MXN'});
        }catch(ex){

        }
        //Google ads
        try{
            const value = this.state.total;
            const checkoutId = this.state.checkoutId;
            // @ts-ignore
            gtag('event', 'conversion', {
                'send_to': 'AW-11357757259/VPRACNr7x_EYEMu-5qcq',
                'value': value,
                'currency': 'MXN',
                'transaction_id': checkoutId
            });
        }catch(ex){
        }
        setTimeout(() =>{
            Cart.saveCartId('');
            // TODO: Change checkouts for orders
            // this.props.history.replace(`/checkouts/${orderId}`);
            this.props.history.replace({
                pathname:`/checkouts/${orderId}`,
                state:{
                    orderId:orderId,
                    paymentMethod:this.state.paymentType
                }
            });
        },2000);

    }

    private onFailedPayment(data: any) {
        this.setState({isPaying:false})

    }
}

export default withIonLifeCycle(Payment);
