/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2023
 */

import {
    IonButton,
    IonIcon,
    IonText, IonLabel, IonToast, IonSpinner, useIonAlert, isPlatform
} from "@ionic/react";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import { money } from "../../lib/money/money";
import Cart from "../../lib/cart/Cart";
import {cart} from "ionicons/icons";


//style
import "../../css/components/searchbarWeb.css";
import {emmitEvent, EVENTS} from "../../lib/events/events";


interface searchbarResultsProps {
    searchResults?: any;
    searchStep: string;
    onDismiss?: any;
    referenceElement?: string
    query?:string

}

const SearchbarResults: React.FC<searchbarResultsProps > = ({
                                                            searchResults={},
                                                            searchStep = '',
                                                            onDismiss = () =>{},
                                                            referenceElement = 'searchbar-web',
                                                            query=''

}) => {
    const history = useHistory();

    const [showToast, setShowToast] = useState(false);
    const [toastMsg, setToastMsg] = useState('');
    const [isAddingToCart,setIsAddingToCart] = useState(false);
    const [left,setLeft] = useState(0);
    const [width,setWidth] = useState(0);


    const [itemAddedToCart,setItemAddedToCart] = useState({
        _id: null
    });
    const [presentAlert] = useIonAlert();
    const goToStore = () =>{
        const isIos = isPlatform('ios');
        window.open(isIos ? 'https://apps.apple.com/us/app/nbio/id1605823846' : 'https://play.google.com/store/apps/details?id=mx.nbio&hl=es_MX&gl=US');
    }
    const askDownloadApp = () =>{
        const isIos = isPlatform('ios');
        presentAlert({
            header: 'Descarga nuestra app',
            message:
                `Para una mejor experiencia, descarga nuestra app`,
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                },
                {
                    text: isIos ? 'Ir a la App Store' : 'Ir a Google Play',
                    role: 'confirm',
                    handler: () => {
                        goToStore();
                    },
                },
            ],
        })
    }
    useEffect(() =>{
        positionSearchBar();
    },[searchStep]);
    useEffect(() =>{
        positionSearchBar();
    },[]);
    useEffect(() =>{
        positionSearchBar();
    },[query]);
    const waitTime = async(time:number) =>{
        return new Promise((resolve) =>{
            resolve(true);
        })
    }
    useEffect(() => {
        // Anything in here is fired on component mount.
        window.addEventListener('resize', onResize);
        return () => {
            // Anything in here is fired on component unmount.
            window.removeEventListener('resize', onResize);
        }
    }, [])
    const onResize = (event: any) => {
        // 991 hide
        if(window.innerWidth <= 991){
            onDismiss();
        }
        positionSearchBar();
    }
    const addToCart = async (e:any, product : any) => {
        setItemAddedToCart(product);
        setIsAddingToCart(true);
        e.stopPropagation();
        await waitTime(2000);
        Cart.addProduct(product._id,1,true,'').then(res => {
            setToastMsg("Producto agregado")
            setShowToast(true);

            try{
                // @ts-ignore
                fbq('track', 'AddToCart',{
                    currency: "MXN",
                    value: product.price,
                    content_ids: [product._id],
                    contents:[
                        {
                            item_id: product._id,
                            item_name: product.name,
                            item_brand: product.brandName || '',
                            item_category: product.categories ? product.categories[0] : '',
                            price: product.price,
                            quantity: 1
                        }
                    ]
                });
            }catch(ex){

            }
            //google tracking
            try{
                // @ts-ignore
                gtag("event", "add_to_cart", {
                    currency: "MXN",
                    value: product.price,
                    items: [
                        {
                            item_id: product._id,
                            item_name: product.name,
                            item_brand: product.brandName || '',
                            item_category: product.categories ? product.categories[0] : '',
                            price: product.price,
                            quantity: 1
                        }
                    ]
                });
            }catch(ex){

            }
        }).catch((ex) =>{
            if(ex === 'Unsupported web view'){
                askDownloadApp();
            }
        }).finally(() =>{
            setIsAddingToCart(false);
            setItemAddedToCart({
                _id: null
            });
            onDismiss();
        })
    }

    const goToProduct = (sku: string) => {
        history.push(`/productos/${sku}`);
    }
    const positionSearchBar = () =>{
        const searchbars = document.getElementsByClassName(referenceElement);
        if(searchbars.length > 0){
            const searchbar = searchbars[searchbars.length - 1]
            if(searchbar){
                // @ts-ignore
                // const input = searchbar.getElementsByClassName('searchbar-input-container');
                // @ts-ignore
                const l = searchbar.offsetLeft + 7;
                // @ts-ignore
                const w = searchbar.offsetWidth - 14;
                setLeft(l);
                setWidth(w);
            }
        }


    }
    const renderPrice = (product:any) => {
        if(product.discount){
            return(
                <IonLabel color="danger">
                    <IonText color={'medium'} className={'line-through mr-1'}>
                        <small>{money(product.originalPrice)}</small>
                    </IonText>
                    <b>{money(product.price)}</b>
                </IonLabel>
            )
        }else{
            return(
                <IonLabel color="danger">
                    <b>{money(product.price)}</b>
                </IonLabel>
            )
        }
    }
    const renderHeader = () =>{
        return (
            <div className={'flex-row ion-justify-content-between ion-padding-start ion-padding-end ion-padding-top'}>
                <IonLabel>
                    <b>
                        {
                            !searchResults?.count ? '' : 'Productos'
                        }
                    </b>
                </IonLabel>
                <IonLabel>
                    <IonText color={'medium'}>
                        Productos encontrados: {searchResults.count}
                    </IonText>
                </IonLabel>
            </div>
        )
    }
    const renderProducts =  () =>{
        return products.map((product: any) => {
            return(
                <div className={'search-result-item ion-padding'} key={product._id}>
                    <img className="search-result-image"
                         onClick={() => goToProduct(product.handleUrl)}
                         src={product.images[0]} alt={`${product.name}`}/>

                    <div className={'flex-col flex-1 ion-margin-end'}>
                        <div className={'ion-text-wrap ion-margin-bottom'}
                             onClick={() => goToProduct(product.handleUrl)}>
                            <b>{product.name}</b>
                        </div>
                        <div>{renderPrice(product)}</div>
                    </div>
                    <IonButton className="btn-on-dark-primary ion-align-self-center"
                               fill="solid"
                               size={"small"}
                               disabled={isAddingToCart}
                               onClick={(e) => addToCart(e,product)}
                    >
                        {
                            isAddingToCart && itemAddedToCart._id === product._id ?
                                <IonSpinner></IonSpinner> :
                                <>Agregar &nbsp;<IonIcon icon={cart}></IonIcon></>
                        }
                    </IonButton>

                </div>
            )
        })
    }
    const renderSpinner = () =>{
        return(
            <div className={'flex-row ion-justify-content-center ion-align-items-center'}>
                <div className={'searching-label'}>
                    Buscando
                </div>
                <IonSpinner color={'primary'} style={{width:'200px',height:'200px'}} name={'dots'}></IonSpinner>

            </div>
        )
    }
    const viewAllResults = () =>{
        history.push({
            pathname:'/busqueda',
            search:`?q=${query}`

        })
    }
    const close = () =>{
        onDismiss();
    }
    const showCart = () =>{
        emmitEvent(EVENTS.OPEN_POPUP_CART,{});
    }
    const goToCart = () =>{
        history.push('/carrito');
    }
    const products =  Array.isArray(searchResults.products) ? searchResults.products : [];
    const searchSpinnerLabels = ['QUERY','SEARCHING'];
        return (
            <div className={searchStep ?    'search-bar-results' : 'search-bar-results-closed'} onClick={() => close()}>
                {/*Ion Toast Not Mobile opens popup */}
                <IonToast isOpen={showToast}  cssClass={'ion-hide-lg-down'}
                          onDidDismiss={() => {setShowToast(false); setToastMsg('')}}
                          buttons={[
                              {
                                  text: 'Ver el carrito',
                                  role: 'info',
                                  handler: () => {
                                      showCart();
                                  }
                              }
                          ]}
                          message={toastMsg}
                          duration={2000} />
                <IonToast isOpen={showToast}  cssClass={'ion-hide-lg-up'}
                          onDidDismiss={() => {setShowToast(false); setToastMsg('')}}
                          buttons={[
                              {
                                  text: 'Ir al carrito',
                                  role: 'info',
                                  handler: () => {
                                      goToCart();
                                  }
                              }
                          ]}
                          message={toastMsg}
                          duration={2000} />
                {/*Ion Toast Mobile calls /carrito */}

                {
                    searchStep ?
                        <div className={"search-results"} style={{left:left,width:width}}>
                            <div className={"search-results-scroll"}>
                                {
                                    searchSpinnerLabels.includes(searchStep) ? renderSpinner() : null
                                }
                                {
                                    searchStep === 'SEARCH_FINISHED' ?
                                        <>
                                            {renderHeader()}
                                            {renderProducts()}
                                        </>
                                         : null
                                }
                                {
                                    searchStep === 'SEARCH_FINISHED' && !searchResults?.count ?
                                        <div className={'ion-text-center'} style={{marginTop:110}}>
                                            <h3>¡Oops! Parece que "<b>{query}</b>" no se encuentra por aquí.</h3>
                                        </div>
                                    : null
                                }
                            </div>
                            {
                                searchStep === 'SEARCH_FINISHED' && searchResults?.count ?
                                    <div className={'flex-row ion-justify-content-center'}>
                                        <IonButton className=""
                                                   fill="clear"
                                                   onClick={(e) => {viewAllResults()}}>
                                            Ver todos los resultados
                                        </IonButton>
                                    </div>
                                    : null
                            }
                        </div> : null
                }
            </div>
        )
}
export default SearchbarResults;

