/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * February 2022
 */
import React from 'react';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonPage,
    withIonLifeCycle,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonItem,
    IonNote,
    IonBackButton
} from '@ionic/react';
import GradientDivider from "../../components/ui/GradientDivider";
import WebHeader from "../../components/ui/WebHeader";


class Info extends React.Component<{}> {


    render() {
        return (
            <IonPage>
                <WebHeader title={'Acerca de Nbio'} showSearchButton={true} showCartButton={true} showMenuButton={true}></WebHeader>
                <IonContent fullscreen>
                    <IonGrid className={'h-100'}>
                        <IonRow className="ion-justify-content-center ion-hide-lg-down">
                            <IonCol size={'12'} sizeLg={'6'}>
                                <IonHeader className={"ion-margin-top ion-no-border"}>
                                    <IonTitle className="ion-text-capitalize">Acerca de Nbio</IonTitle>
                                </IonHeader>
                            </IonCol>
                        </IonRow>
                        <IonRow className={"h-100 ion-justify-content-center"}>
                            <IonCol size-md="6" className={"flex-col ion-justify-content-between"}>
                                <div>
                                    <IonItem routerLink={'/preguntas-frecuentes'}
                                             routerDirection={'forward'}
                                             detail>Preguntas frecuentes</IonItem>
                                    <IonItem routerLink={'/politicas-de-privacidad'}
                                             routerDirection={'forward'}
                                        detail>Políticas de privacidad</IonItem>

                                    <IonItem routerLink={'/terminos-y-condiciones'}
                                             routerDirection={'forward'}
                                             detail>Términos y condiciones</IonItem>

                                    <IonItem className={"ion-margin-top"}>v1.58</IonItem>

                                </div>
                                <a className={"no-decoration"} href={'https://www.nodeport.mx/'}>
                                    <IonNote><small>Desarrollado por Nodeport</small></IonNote>
                                </a>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonPage>
        )
    }
}

export default withIonLifeCycle(Info);
